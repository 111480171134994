.policy-container {
  padding: 30px;
  width: 100%;
  background: #24243e;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  color: white;
  flex-direction: column;
  gap: 20px;
}
